import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from "../_context/store.context";
import { Box, Container, Typography } from '@material-ui/core';
import MainLayout from '../_layouts/main';
import { mobilizeiSubscriptionsService } from "../_services/mobilizei/subscriptions.service";
import CreditCardDataForm from '../_components/CreditCardDataForm';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
    title: {
        "& span": {
            position: 'relative',
            "&::after": {
                content: '""',
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "100%",
                height: ".26em",
                borderRadius: "20px",
                backgroundColor: "#3cd568",
                zIndex: -1,
                WebkitTransform: "translateY(-50%)",
                msTransform: "translateY(-50%)",
                transform: "translateY(-50%)"
            }
        }

    }
}))

export default function PaymentMethodPage() {
    const classes = useStyles();
    const history = useHistory();
    const [store] = useStore();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ creditCard: {} })

    const submit = (creditCardToken) => {
        setLoading(true);

        mobilizeiSubscriptionsService.createPaymentMethod(store.subscriptionId, { creditCardToken })
            .then(() => {
                toast.success('Cartão de crédito cadastrado com sucesso!');
                history.goBack();
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível cadastrar o seu cartão de crédito. Por favor, confira os seus dados e tente novamente. (#${errorCode})`);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <MainLayout>
            <Container maxWidth="sm">
                <Box mb={4}>
                    <Typography align="center" variant="h3" className={classes.title}>
                        Cadastrar cartão de cr<span>édito</span>
                    </Typography>
                </Box>

                <CreditCardDataForm
                    formData={formData}
                    disclaimer={<>Ao clicar no botão "Cadastrar cartão", você concorda com os nossos <a href="https://mobilizei.com.br/termos-de-uso" target="_blank" rel="noreferrer">Termos de Uso</a> e a nossa <a href="https://mobilizei.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">Política de Privacidade</a>. Você também garante que os produtos e o conteúdo da sua loja estão de acordo com a <a href="https://support.google.com/googleplay/android-developer/topic/9877466?hl=pt-BR" target="_blank">Política de Conteúdo Restrito da Play Store</a> e seguem as <a target="_blank" href="https://developer.apple.com/app-store/review/guidelines/">Diretrizes da App Store</a>. O valor do plano contratado será cobrado automaticamente no seu cartão de crédito na data de vencimento da sua assinatura. Você poderá cancelar a sua assinatura a qualquer momento para evitar cobranças futuras.</>}
                    loading={loading}
                    primaryButtonAction={submit}
                    primaryButtonLabel={'Cadastrar cartão'}
                    secondaryButtonAction={() => history.goBack()}
                    secondaryButtonLabel={'Voltar'}
                    setFormData={setFormData}
                    setLoading={setLoading}
                />
            </Container>
        </MainLayout>
    );
}