import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from "../_context/store.context";
import { Box, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import MainLayout from '../_layouts/main';
import { mobilizeiSubscriptionsService } from "../_services/mobilizei/subscriptions.service";
import BillingInformationSection from './BillingInformationSection';
import PaymentMethodSection from './PaymentMethodSection';
import InvoicesHistorySection from './InvoicesHistorySection';
import SubscriptionSection from './SubscriptionSection';

const useStyles = makeStyles(() => ({
    title: {
        "& span": {
            position: 'relative',
            "&::after": {
                content: '""',
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "100%",
                height: ".26em",
                borderRadius: "20px",
                backgroundColor: "#3cd568",
                zIndex: -1,
                WebkitTransform: "translateY(-50%)",
                msTransform: "translateY(-50%)",
                transform: "translateY(-50%)"
            }
        }

    }
}))

export default function MyAccountPage() {
    const classes = useStyles();
    const [store] = useStore();

    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState({});

    useEffect(() => {
        mobilizeiSubscriptionsService.getById(store.subscriptionId)
            .then(subscriptionData => {
                setSubscription(subscriptionData);
                setLoading(false);
            })
            .catch(e => {
                setSubscription({});
                setLoading(false);
            })
    }, []);

    return (
        <MainLayout>
            <Container maxWidth="md">
                <Box mb={2}>
                    <Typography variant="h3" className={classes.title}>
                        Minha C<span>onta</span>
                    </Typography>
                </Box>

                {loading ? (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '50vh' }}
                    >
                        <Grid item xs={4}>
                            <CircularProgress color="primary" />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <SubscriptionSection subscription={subscription} />
                        <BillingInformationSection subscription={subscription} />
                        <PaymentMethodSection subscription={subscription} />
                        <InvoicesHistorySection subscription={subscription} />
                    </>
                )}
            </Container>
        </MainLayout>
    );
}