import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import config from '../_helpers/config';

export default function CreditCardStep() {
    return (
        <Container maxWidth="sm">
            <Box alignItems="center" display="flex" justifyContent="center">
                <img src={require(`../_assets/img/check.png`).default} alt="check" width="100" />
            </Box>
            
            <Box mb={6} mt={2}>
                <Typography variant="h4" align="center">
                    Conta ativada com sucesso!
                </Typography>
            </Box>

            <Grid item xs={12}>
                <Box marginBottom={4}>
                    <Typography variant="body1" align="center">
                        Muito obrigado e seja bem-vindo(a) à Mobilizei! Em breve você receberá um e-mail com os detalhes do pagamento.
                    </Typography>
                </Box>

                <Box marginBottom={8}>
                    <Typography variant="body1" align="center">
                        Clique no botão abaixo para acessar o nosso painel de controle e configurar os seus aplicativos.
                    </Typography>
                </Box>
            </Grid>

            <Box alignItems="center" display="flex" justifyContent="center" m={8}>
                <Button 
                    color="primary" 
                    disableElevation 
                    onClick={() => window.location.href = `${config.MOBILIZEI_PANEL_URL}?at=${window.localStorage.getItem('at')}`}
                    variant="contained" 
                >
                    Configurar aplicativos
                </Button>
            </Box>
        </Container>
    );
}