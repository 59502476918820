const axios = require('axios')

export const postalCodeService = {
    getAddressByPostalCode
};

function getAddressByPostalCode(postalCode) {
    postalCode = postalCode.replace('.', '').replace('-', '');

    return axios.get(`https://viacep.com.br/ws/${postalCode}/json/`, { timeout: 2000 })
    .then(res => {
        if(res.status === 200) {
            const data = res.data;

            if(data['error']) {
                return null;
            }

            return res.data;
        }
        return null;
    }).catch(() => {
        return null;
    })
}