import React from 'react';
import { withRouter } from 'react-router-dom';
import { StoreContext } from '../_context/store.context';
import { storeStatus } from '../_helpers/constants';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    render() {
      return (
        <StoreContext.Consumer>
          {authUser => {
            // In case we don't have an user, redirect to error page
            if (!authUser[0]) {
              return this.props.history.push('/error')
            }

            // Access is allowed
            if (condition(authUser[0])) {
              return <Component {...this.props} />
            }

            // Active user without a subscription trying to access a route 
            // available only for subscription holders
            if (authUser[0] && authUser[0].status === storeStatus.ACTIVE && !authUser[0].subscriptionId) {
              return this.props.history.push('/pricing')
            }

            // Active user trying to access a route only for not active users
            if (authUser[0] && authUser[0].status === storeStatus.ACTIVE) {
              return this.props.history.push('/')
            }

            // An not active user trying to access an area only for active users
            if (authUser[0] && !authUser[0].status !== storeStatus.ACTIVE) {
              return this.props.history.push('/pricing')
            }
          }}
        </StoreContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;