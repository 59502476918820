import { apiFetcher } from "./apiFetcher";

export const mobilizeiStoresService = {
    getStoreById,
    getStoreFeatures,
    getThemeById
};

function getStoreById(platformId, storeId) {
  const url = `platforms/${platformId}/stores/${storeId}`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function getStoreFeatures(platformId, storeId) {
  const url = `platforms/${platformId}/stores/${storeId}/features`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function getThemeById(platformId, storeId, themeId) {
    const url = `platforms/${platformId}/stores/${storeId}/themes/${themeId}`
  
    return apiFetcher(url, {
      method: "GET"
    }).then(response => response);
}