import React from 'react';
import Header from '../_components/Header'
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        background: `url(${require('../_assets/img/brand-dots.svg').default}) no-repeat -350px 100px`,
        paddingTop: 50,
        "&:before": {
            background: `url(${require('../_assets/img/brand-circle.svg').default}) no-repeat`,
            backgroundSize: '100% 100%',
            content: '""',
            height: 500,
            position: "absolute",
            right: -300,
            top: -150,
            width: 500,
            zIndex: -999,
            '@media (max-width: 960px)': {
                right: -400,
                top: -200,
            }
        },
        '@media (max-width: 960px)': {
            background: `url(${require('../_assets/img/brand-dots.svg').default}) no-repeat -450px 200px`,
        }
    }
}));

export default function MainLayout({ children }) {
    const classes = useStyles();

    return (
        <>
            {/* Show the header only if the application is not being loaded on an iframe */}
            {(window.location === window.parent.location) && <Header />}
            <Container maxWidth={false} component="main" color="white" className={classes.container}>
                {children}
            </Container>
        </>
    );
}