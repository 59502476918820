import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputMask from "react-input-mask";

const useStyles = makeStyles(() => ({
    main: {
        lineHeight: '.75rem'
    },
    label: {
        display: "block",
        margin: "0",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.8,
        color: "#000"
    },
    input: {
        display: "block",
        WebkitBoxSizing: "border-box",
        boxSizing: "border-box",
        width: "100%",
        height: "2.77083rem",
        margin: "0 0",
        padding: "0.66667rem",
        border: "2px solid #000",
        borderRadius: "8px",
        backgroundColor: "#fff",
        WebkitBoxShadow: "none",
        boxShadow: "none",
        fontFamily: "inherit",
        fontSize: "1rem",
        fontWeight: "normal",
        lineHeight: 1.5,
        color: "#000",
        WebkitTransition: "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
        transition: [
            "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
            "box-shadow 0.5s, border-color 0.25s ease-in-out",
            "box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s"
        ],
        WebkitAppearance: "none",
        MozAppearance: "none",
        appearance: "none",
        "&:disabled": {
            backgroundColor: "#e6e6e6",
            cursor: "not-allowed"
        },
        "&:focus": {
            outline: "none",
            border: "2px solid #3CD568",
            backgroundColor: "#fff",
            WebkitBoxShadow: "none",
            boxShadow: "none",
            WebkitTransition: "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
            transition: [
                "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
                "box-shadow 0.5s, border-color 0.25s ease-in-out",
                "box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s"
            ]
        }
    },
    error: {
        lineHeight: '.75rem',
        "& label": {
            color: '#cc4b37!important'
        },
        "& input": {
            color: '#cc4b37!important',
            backgroundColor: '#faedeb',
            borderColor: '#cc4b37!important'
        },
        "& span": {
            color: '#cc4b37!important',
            fontSize: '.75rem',
            fontWeight: 700
        },
    },
    helper: {
        fontSize: '.75rem',
        fontStyle: 'italic',
        margin: '5px 0 0 0'
    }
}));

export default function MbTextField({ disabled, error, helper, label, mask, name, value, onBlur, onChange, inputProps }) {
    const classes = useStyles();

    return (
        <div className={!!error ? classes.error : classes.main}>
            <label className={classes.label}>{label}</label>
            <InputMask
                className={classes.input}
                disabled={disabled}
                mask={mask}
                maskChar={null}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                value={value}
                {...inputProps}
            />
            {!!error && <span>{error}</span>}
            {!!helper && <p className={classes.helper}>{helper}</p>}
        </div>
    );
}