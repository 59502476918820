import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { usePlan } from "../_context/plan.context";
import { plans } from '../_helpers/constants';

const useStyles = makeStyles(() => ({
    wrapper: {
        borderRadius: 25,
        padding: 5,
        margin: 'auto',
        width: 'fit-content'
    },
    button: {
        borderRadius: 25,
        fontSize: 16,
        padding: 10,
        textTransform: 'none',
        minWidth: 180
    }
}));

export default function PlanPicker() {
    const classes = useStyles();
    const [plan, setPlan] = usePlan();

    return (
        <div className={classes.wrapper}>
            <Button
                variant={plan === plans.PLAN_MONTHLY_2023 ? 'contained' : 'text'}
                color="primary"
                disableElevation
                className={classes.button}
                onClick={() => {
                    window.localStorage.setItem('selectedPlan', plans.PLAN_MONTHLY_2023);
                    setPlan(plans.PLAN_MONTHLY_2023)
                }}
            >
                Plano mensal
            </Button>
            <Button
                variant={plan === plans.PLAN_ANNUAL_2023 ? 'contained' : 'text'}
                color="primary"
                disableElevation
                className={classes.button}
                onClick={() => {
                    window.localStorage.setItem('selectedPlan', plans.PLAN_ANNUAL_2023);
                    setPlan(plans.PLAN_ANNUAL_2023)
                }}
            >
                Plano anual
            </Button>
        </div>
    );
}