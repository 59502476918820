import React, { useState } from 'react';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import MbTextField from '../_components/MbTextField';
import MbSelectField from '../_components/MbSelectField';
import { postalCodeService } from '../_services/postalCode.service';
import _ from 'lodash';

export default function UserDataForm({ formData, loading, setFormData, primaryButtonLabel, primaryButtonAction, primaryButtonId, secondaryButtonLabel, secondaryButtonAction }) {
    const [errors, setErrors] = useState({});
    const [loadingAddress, setLoadingAddress] = useState(false);

    const handleZipCodeChange = (e) => {
        setLoadingAddress(true);

        postalCodeService.getAddressByPostalCode(e.target.value)
            .then(response => {
                handlePropertyChange({ target: { name: 'street', value: response['logradouro'] } });
                handlePropertyChange({ target: { name: 'city', value: response['localidade'] } });
                handlePropertyChange({ target: { name: 'state', value: response['uf'] } });
                handlePropertyChange({ target: { name: 'district', value: response['bairro'] } });
            })
            .catch(() => null)
            .finally(() => setLoadingAddress(false));
    }

    const handlePropertyChange = (e) => {
        // Set the changed property
        setFormData(currentFormData => {
            let newFormData = { ...currentFormData };
            _.set(newFormData, e.target.name, e.target.value)
            return newFormData;
        });

        // Clear the error of the changed property
        setErrors(currentErrors => ({ ...currentErrors, [e.target.name]: null }))
    }

    const validateFormData = (e) => {
        e.preventDefault();

        const errors = {};
        setErrors({});

        const requiredMsg = "Este campo é obrigatório.";
        const regEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

        // Validate required properties presence
        let requiredProperties = ['cpfCnpj', 'email', 'name', 'phone', 'zipCode', 'number', 'street', 'city', 'state', 'district'];
        requiredProperties.forEach(property => {
            if (_.isEmpty(_.get(formData, property))) {
                _.set(errors, property, requiredMsg)
            }
        })

        // Check if email is valid
        if (!regEmail.test(formData.email)) {
            errors['email'] = "Insira um email válido.";
        }

        // Check if CPF or CNPJ is valid
        if (!cpf.isValid(formData.cpfCnpj) && !cnpj.isValid(formData.cpfCnpj)) {
            errors['cpfCnpj'] = "Insira um número válido.";
        }

        // Check if we can proceed
        if (_.isEmpty(errors)) {
            primaryButtonAction();
        } else {
            setErrors(errors);
        }
    }

    return (
        <form id="user_data_form" onSubmit={validateFormData}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} >
                    <MbTextField
                        error={errors.name}
                        label="Nome completo ou razão social:"
                        name="name"
                        onChange={handlePropertyChange}
                        value={formData.name}
                    />
                </Grid>

                <Grid item xs={6}>
                    <MbTextField
                        error={errors.cpfCnpj}
                        label="CPF ou CNPJ:"
                        mask={formData.cpfCnpj.length < 15 ? "999.999.999-999" : "99.999.999/9999-99"}
                        name="cpfCnpj"
                        onChange={handlePropertyChange}
                        value={formData.cpfCnpj}
                    />
                </Grid>

                <Grid item xs={6}>
                    <MbTextField
                        error={errors.phone}
                        label="Celular:"
                        mask={"(99) 99999-9999"}
                        name="phone"
                        onChange={handlePropertyChange}
                        value={formData.phone}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MbTextField
                        error={errors.email}
                        label="E-mail:"
                        name="email"
                        onChange={handlePropertyChange}
                        value={formData.email}
                        helper="As comunicações sobre sua assinatura serão enviadas para este e-mail."
                    />
                </Grid>

                <Grid item xs={6}>
                    <MbTextField
                        error={errors.zipCode}
                        label="CEP:"
                        mask="99999-999"
                        name="zipCode"
                        onBlur={handleZipCodeChange}
                        onChange={handlePropertyChange}
                        value={formData.zipCode}
                    />
                </Grid>

                {loadingAddress &&
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <CircularProgress color="primary" />
                    </Grid>
                }

                <Grid item xs={9}>
                    <MbTextField
                        error={errors.street}
                        label="Endereço:"
                        name="street"
                        onChange={handlePropertyChange}
                        value={formData.street}
                    />
                </Grid>

                <Grid item xs={3}>
                    <MbTextField
                        error={errors.number}
                        label="Número:"
                        name="number"
                        onChange={handlePropertyChange}
                        value={formData.number}
                    />
                </Grid>

                <Grid item xs={9}>
                    <MbTextField
                        error={errors.city}
                        label="Cidade:"
                        name="city"
                        onChange={handlePropertyChange}
                        value={formData.city}
                    />
                </Grid>

                <Grid item xs={3}>
                    <MbSelectField
                        error={errors.state}
                        label="Estado:"
                        name="state"
                        onChange={handlePropertyChange}
                        value={formData.state}
                    >
                        <option value=""></option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                    </MbSelectField>
                </Grid>

                <Grid item xs={6}>
                    <MbTextField
                        error={errors.district}
                        label="Bairro:"
                        name="district"
                        onChange={handlePropertyChange}
                        value={formData.district}
                    />
                </Grid>

                <Grid item xs={6}>
                    <MbTextField
                        error={errors.complement}
                        label="Complemento:"
                        name="complement"
                        onChange={handlePropertyChange}
                        value={formData.complement}
                    />
                </Grid>
            </Grid>

            {loading ? (
                <Box alignItems="center" display="flex" justifyContent="center" my={8}>
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <Box alignItems="center" display="flex" justifyContent="center" my={8}>
                    {!_.isEmpty(secondaryButtonLabel) &&
                        <Button color="primary" style={{ marginRight: 50 }} onClick={secondaryButtonAction}>
                            {secondaryButtonLabel}
                        </Button>
                    }

                    <Button type='submit' id={primaryButtonId} variant="contained" color="primary" disableElevation>
                        {primaryButtonLabel}
                    </Button>
                </Box>
            )}
        </form>
    );
}