import React, { useEffect } from 'react';
import { usePlan } from "../_context/plan.context";
import { useStore } from "../_context/store.context";
import MainLayout from '../_layouts/main';
import AppleDeveloperLogo from '../_assets/img/apple-developer.png';
import GooglePlayLogo from '../_assets/img/googleplay.svg';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PlanPicker from '../_components/PlanPicker';
import CheckIcon from '@material-ui/icons/Check';
import { plans, platforms } from '../_helpers/constants';

const useStyles = makeStyles(() => ({
    container: {
        textAlign: 'center'
    },
    warning: {
        fontWeight: 700
    },
    planContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        "& ul": {
            textAlign: 'left'
        },
        "& li": {
            alignItems: 'center',
            display: 'flex',
            margin: '5px auto',
            "& svg": {
                color: '#35c45f',
                margin: 'auto 5px auto -29px'
            }
        },
    },
    price: {
        fontSize: 50,
        fontWeight: 700,
        margin: '0 0 -20px 0',
        textAlign: 'center',
        "& strong": {
            fontSize: 100
        }
    },
    annualPrice: {
        fontSize: 20,
        margin: 0
    },
    note: {
        fontSize: 14,
        margin: 0
    },
    disclaimer: {
        color: '#858585',
        fontSize: 16,
        marginBottom: 50,
        textAlign: 'center',
        "& p": {
            marginBottom: 10
        },
        "& img": {
            display: 'block',
            margin: '1em auto 0',
            height: 'auto',
            verticalAlign: 'middle',
            '@media (min-width: 960px)': {
                display: 'inline-block',
                margin: '0 0 0 30px'
            }
        },
        "& .dev-account-wrapper": {
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 960px)': {
                display: 'inline-block',
            }
        },
        "& .dev-account": {
            display: 'block',
            "& span": {
                marginLeft: 5
            },
            '@media (min-width: 960px)': {
                display: 'inline-block',
            }
        }
    }
}));

export default function PricingPage() {
    const history = useHistory();
    const [plan, setPlan] = usePlan();
    const [store] = useStore();
    const classes = useStyles();

    const goToHire = () => {
        if (window.location !== window.parent.location) {
            window.parent.location = `${window.location.origin}/hire`
            return;
        }

        history.push('/hire')
    }

    const goToContactPage = () => {
        window.parent.location = 'https://mobilizei.com.br/#contact';
    }

    useEffect(() => {
        if (store.platformId === platforms.TRAY) {
            setPlan(plans.PLAN_MONTHLY_2023)
        }
    }, [store]);

    return (
        <MainLayout>
            <Container maxWidth="md" className={classes.container}>
                {store.status === "trial_ended" &&
                    <Typography variant="h3" className={classes.warning}>
                        Seu período de testes acabou!
                    </Typography>
                }

                <p>Ative agora sua conta e publique os seus aplicativos</p>

                <div className={classes.planContainer}>
                    {store.platformId === platforms.NUVEMSHOP && <PlanPicker />}

                    {plan === plans.PLAN_ANNUAL_2023 ? (
                        <>
                            <p className={classes.price}>R$<strong>129</strong>/mês</p>
                            <p className={classes.annualPrice}>R$ 1.548,00 por ano</p>
                            <p className={classes.note}>no boleto, pix ou cartão</p>
                        </>
                    ) : (
                        <>
                            <p className={classes.price}>R$<strong>149</strong>/mês</p>
                            {store.platformId === platforms.NUVEMSHOP && <p className={classes.note}>no cartão de crédito</p>}
                        </>
                    )}

                    <ul className="no-bullet">
                        <li><CheckIcon />notificações push ilimitadas</li>
                        <li><CheckIcon />avisos de carrinho abandonado</li>
                        <li><CheckIcon />integrado com a sua loja</li>
                        <li><CheckIcon />lista de desejos</li>
                        <li><CheckIcon />layout personalizável</li>
                        <li><CheckIcon />diversos temas</li>
                        <li><CheckIcon />aplicativo Android e iOS</li>
                    </ul>
                </div>

                {store.platformId === platforms.TRAY ? (
                    <Box alignItems="center" display="flex" justifyContent="center" m={6}>
                        <Button variant="contained" color="primary" disableElevation onClick={() => goToContactPage()}>
                            Entre em contato
                        </Button>
                    </Box>
                ) : (
                    <Box alignItems="center" display="flex" justifyContent="center" m={6}>
                        <Button id="hire_start" variant="contained" color="primary" disableElevation onClick={() => goToHire()}>
                            Ativar e publicar aplicativos
                        </Button>
                    </Box>
                )}

                <div className={classes.disclaimer}>
                    <p>Para publicar os seus aplicativos Android ou iOS é necessário criar as seguintes contas:</p>
                    <div className="dev-account-wrapper">
                        <div className="dev-account">
                            <img src={GooglePlayLogo} width="134" height="30" alt="Google Play Developer Account" />
                            <span>U$25 (taxa única)</span>
                        </div>

                        <div className="dev-account">
                            <img src={AppleDeveloperLogo} width="134" height="30" alt="Apple Developer Account" />
                            <span>U$99/ano</span>
                        </div>
                    </div>
                </div>
            </Container>
        </MainLayout>
    );
}