import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { mobilizeiSubscriptionsService } from '../_services/mobilizei/subscriptions.service';
import MainLayout from '../_layouts/main';
import Step1 from './Step1';
import Step2 from './Step2';
import CreditCardStep from './CreditCardStep';
import BankSlipData from '../_components/BankSlipData';
import PixData from '../_components/PixData';
import { usePlan } from "../_context/plan.context";
import { toast } from "react-toastify";

export default function SubscriptionPage() {
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [subscription, setSubscription] = useState({});
    const [plan, setPlan] = usePlan();

    const [formData, setFormData] = useState({
        cpfCnpj: '',
        email: '',
        name: '',
        // phone: '',
        paymentMethod: 'credit_card',
        planId: plan,
        zipCode: '',
        number: '',
        street: '',
        city: '',
        state: '',
        district: '',
        complement: '',
        creditCardToken: '',
        creditCard: {
            number: '',
            cvv: '',
            name: '',
            expiration: ''
        }
    })

    useEffect(() => {
        let selectedPlan = window.localStorage.getItem('selectedPlan');
        if(selectedPlan) {
            setPlan(selectedPlan);
        }
    }, []);

    const changeStep = step => {
        setCurrentStep(step)
        window.scrollTo(0, 0);
    }

    async function handleSubmit(creditCardToken) {
        setLoading(true);

        // Crete a 'data' object without the creditCard attribute (so we don't POST the credit card data)
        const { creditCard, ...data } = formData;

        await mobilizeiSubscriptionsService.create({ ...data, creditCardToken })
            .then(response => {
                setSubscription(response);
                changeStep(3);
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível realizar a ativação da sua conta. Por favor, confira os seus dados e tente novamente. (#${errorCode})`)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <MainLayout>
            {currentStep === 1 &&
                <Step1
                    formData={formData}
                    goToStep2={() => changeStep(2)}
                    setFormData={setFormData}
                />
            }

            {currentStep === 2 &&
                <Step2
                    formData={formData}
                    goToPreviousStep={() => changeStep(1)}
                    goToNextStep={handleSubmit}
                    loading={loading}
                    setFormData={setFormData}
                    setLoading={setLoading}
                />
            }

            {currentStep === 3 && formData.paymentMethod === 'credit_card' &&
                <CreditCardStep formData={formData} subscription={subscription} />
            }

            {currentStep === 3 && formData.paymentMethod === 'bank_slip' &&
                <BankSlipData
                    title="Pagamento via boleto"
                    text={<>O acesso à sua conta será liberado após a compensação do boleto, em até 3 dias úteis. Te manteremos informado a respeito do status do pagamento através do e-mail <strong>{formData.email}</strong>.</>}
                    disclaimer="Próximo da data de vencimento você irá receber no seu e-mail um link para efetuar a renovação da sua assinatura. Utilizando este link, você poderá efetuar um novo pagamento via boleto ou então utilizar algum outro método de pagamento."
                    invoice={_.get(subscription, 'invoice', {})}
                />
            }

            {currentStep === 3 && formData.paymentMethod === 'pix' &&
                <PixData
                    title="Pagamento Pix"
                    text={<>Efetue o pagamento utilizando os dados abaixo. Te avisaremos pelo e-mail <strong>{formData.email}</strong> assim que o acesso à sua conta for liberado.</>}
                    disclaimer="Próximo da data de vencimento você irá receber no seu e-mail um link para efetuar a renovação da sua assinatura. Utilizando este link, você poderá efetuar um novo pagamento via Pix ou então utilizar algum outro método de pagamento."
                    invoice={_.get(subscription, 'invoice', {})}
                />
            }
        </MainLayout>
    );
}