import * as React from 'react'
import { plans } from '../_helpers/constants';

const PlanContext = React.createContext()

function usePlan() {
    const context = React.useContext(PlanContext);
    if (!context) {
        throw new Error(`usePlan must be used within a PlanContext`);
    }
    return context;
}

function PlanProvider(props) {
  const [plan, setPlan] = React.useState(plans.PLAN_ANNUAL_2023);

  const value = React.useMemo(() => [plan, setPlan], [plan])

  return <PlanContext.Provider value={value} {...props} />
}

export { PlanContext, PlanProvider, usePlan }