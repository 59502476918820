import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
          main: '#3cd568',
        }
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: "white",
                    margin: "0",
                    padding: "0",
                    fontFamily: '"Cabin", sans-serif',
                    fontSize: 24,
                    fontWeight: "normal",
                    lineHeight: 1.5,
                    WebkitFontSmoothing: "antialiased",
                    MozOsxFontSmoothing: "grayscale",
                },
                "#root": {
                    overflow: 'hidden',
                    position: 'relative'
                },
                a: {
                    color: '#3cd568',
                    cursor: 'pointer',
                    textDecoration: 'none'
                },
                ul: {
                    listStyle: 'none'
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: 8, 
                lineHeight: 1,
                padding: '15px 35px',
                '@media (max-width: 600px)': {
                    background: `url(${require('./_assets/img/brand-dots.svg').default}) no-repeat -430px 200px`,
                }
            },
            containedPrimary: {
                color: '#fff',
                fontFamily: '"Cabin", sans-serif',
                fontSize: 24,
                fontWeight: 700,
                textTransform: 'none'
            },
            textPrimary: {
                textTransform: 'none'
            },
            textSecondary: {
                textTransform: 'none'
            }
        },
        MuiTableCell: {
            root: {
                fontFamily: '"Cabin", sans-serif!important',
            },
            head: {
                fontWeight: 'bold!important'
            },
            body: {
                color: '#858585'
            }
        },
        MuiToolbar: {
            gutters: {
                '@media (min-width: 600px)': {
                    paddingLeft: '12%',
                    paddingRight: '12%'
                }
            },
        },
        MuiTypography: {
            root: {
                fontFamily: '"Cabin", sans-serif!important'
            }
        }
    }
});

export default theme;