import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import _ from "lodash";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    content: {
        color: '#858585'
    }
}))

export default function BillingInformationSection({ subscription }) {
    const classes = useStyles();
    const history = useHistory();

    const billingInformation = _.get(subscription, 'billingInformation', {});
    const { city, complement, cpfCnpj, district, email, name, phone, number, state, street, zipCode } = billingInformation;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <hr />
            </Grid>

            <Grid item xs={12} md={5}>
                <Typography variant="h5">
                    Dados de Faturamento
                </Typography>
            </Grid>

            <Grid item xs={12} md={7} className={classes.content}>
                <Typography variant="body1">
                    {email}
                </Typography>

                <Box my={2}>
                    <Typography variant="body1">
                        {name}  <br />
                        {cpfCnpj} <br />
                        {phone}
                    </Typography>
                </Box>

                <Typography variant="body1">
                    {street}, {number}{_.isEmpty(complement) ? '' : `, ${complement}` } <br />
                    {city} - {state} <br />
                    {district} <br />
                    {zipCode}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box alignItems="center" display="flex" justifyContent="flex-end" mt={-5}>
                    <Button color="primary" onClick={() => history.push("/billing_data")}>
                        Editar
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}