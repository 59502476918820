import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { formatFirestoreTimestamp, getPaymentMethodLabel, getInvoiceStatusChip, mapIntegerInCentsToCurrency } from '../_helpers/mappers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _ from "lodash";

const useStyles = makeStyles(() => ({
    empty: {
        color: '#858585'
    }
}))

export default function InvoicesHistorySection({ subscription }) {
    const classes = useStyles();
    const invoices = _.get(subscription, 'invoices', []);

    return (
        <Box mb={8}>
            <Box my={4}>
                <hr />
            </Box>

            <Box mb={4}>
                <Typography variant="h5">
                    Histórico de Cobranças
                </Typography>
            </Box>

            {_.isEmpty(invoices) ? (
                <Typography variant="body1" align="center" className={classes.empty}>
                    Não existe nenhuma cobrança cadastrada.
                </Typography>
            ) : (
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Vencimento</TableCell>
                                <TableCell align="center">Data de Pagamento</TableCell>
                                <TableCell align="center">Valor</TableCell>
                                <TableCell align="center">Método de Pagamento</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices.map((invoice) => (
                                <TableRow key={invoice.id}>
                                    <TableCell align="center">{formatFirestoreTimestamp(invoice.dueDate, 'DD/MM/YYYY') || '-'}</TableCell>
                                    <TableCell align="center">{formatFirestoreTimestamp(invoice.paidAt, 'DD/MM/YYYY') || '-'}</TableCell>
                                    <TableCell align="center">{mapIntegerInCentsToCurrency(invoice.totalInCents) || '-'}</TableCell>
                                    <TableCell align="center">{getPaymentMethodLabel(invoice.paymentMethod) || '-'}</TableCell>
                                    <TableCell align="center">{getInvoiceStatusChip(invoice.status)}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Ver fatura">
                                            <IconButton onClick={() => window.open(`/faturas/${invoice.id}`, "_blank")}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
}