import config from "../../_helpers/config";

export const apiFetcher = (path, init, attempt = 1) => {
    const at = window.localStorage.getItem('at');
    let options = init;

    let url = `${config.MOBILIZEI_API_BASE_URL}/${path}`;

    let headers = new Headers();
    headers.append('Authorization', `Bearer ${at}`);
    headers.append('Content-Type', 'application/json');
    options['headers'] = headers;

    return fetch(url, init).then(response => {
        if (response.status === 204) {
            return;
        }

        // Handle 401 and 403 errors
        if (response.status === 401 || response.status === 403) {

            // Remove the at from the local storage and reload to show
            // the error message
            window.localStorage.removeItem('at');
            window.location.reload();

            return Promise.reject();
        }

        // handle 404 errors
        if (response.status === 404 ) {
            return Promise.reject();
        }

        return response.json().then(data => {
            if (!response.ok) {
                const error = data || response.statusText;
                return Promise.reject(error);
            }
            return data;
        });
    });
};