const devConfig = {
  MOBILIZEI_API_BASE_URL: "https://api-dev.mobilizei.com.br/v1",
  MOBILIZEI_PANEL_URL: "https://painel-dev.mobilizei.com.br",
  IUGU_ACCOUNT_ID: "CFFC16C7AB62EE984964E11ADFA8E022",
  IUGU_TEST_MODE: true
}

const prodConfig = {
  // MOBILIZEI_API_BASE_URL: "http://localhost:5001/mobilizei/us-central1/main/v1",
  MOBILIZEI_API_BASE_URL: "https://api.mobilizei.com.br/v1",
  MOBILIZEI_PANEL_URL: "https://painel.mobilizei.com.br",
  IUGU_ACCOUNT_ID: "CFFC16C7AB62EE984964E11ADFA8E022",
  IUGU_TEST_MODE: false
}

const config = process.env.REACT_APP_ENV === 'prod' ? prodConfig : devConfig;

export default config;