import * as React from 'react'

const StoreContext = React.createContext()

function useStore() {
    const context = React.useContext(StoreContext);
    if (!context) {
        throw new Error(`useStore must be used within a StoreContext`);
    }
    return context;
}

function StoreProvider(props) {
  const [user, setUser] = React.useState({});

  const value = React.useMemo(() => [user, setUser], [user])

  return <StoreContext.Provider value={value} {...props} />
}

export { StoreContext, StoreProvider, useStore }