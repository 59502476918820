import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { mobilizeiStoresService } from "../_services/mobilizei/stores.service";
import { useStore } from "../_context/store.context";
import jwt from "jsonwebtoken";
import { CircularProgress, Grid } from '@material-ui/core';

export default function Auth({ children }) {
    const [loading, setLoading] = useState(true);
    const [, setStore] = useStore(null);

    useEffect(() => {
        // Get url params
        const urlParams = new URLSearchParams(window.location.search);
        let accessToken = urlParams.get('at');

        // Check if it was possible to get the access token from the url
        // If not, try to get it from the localStorage
        if (accessToken) {
            window.localStorage.setItem('at', accessToken);
        } else {
            accessToken = window.localStorage.getItem('at');
        }

        // Try to decode the access token
        const decodedAccessToken = jwt.decode(accessToken);

        // Try to get the platformId and the storeId from the decoded access token
        const platformId = _.get(decodedAccessToken, 'claims.platformId', null);
        const storeId = _.get(decodedAccessToken, 'claims.storeId', null);

        // If we don't have an a platormId or a storeId return the error message
        if (_.isEmpty(platformId) || _.isEmpty(storeId)) {
            window.localStorage.removeItem('at');
            setStore(null);
            setLoading(false);
            return;
        } else {
            mobilizeiStoresService.getStoreById(platformId, storeId)
                .then(storeData => {
                    setStore({ ...storeData, platformId });
                    setLoading(false);
                })
                .catch(e => {
                    setStore(null);
                    setLoading(false);
                })
        }
    }, []);

    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={4}>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>
        )
    } else {
        return <>{children}</>
    }
}