import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import CreditCardDataForm from '../_components/CreditCardDataForm';
import { usePlan } from "../_context/plan.context";
import { plans } from '../_helpers/constants';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    paymentMethodWrapper: {
        alignItems: 'center',
        border: '2px solid #C6C6C6',
        borderRadius: 8,
        color: '#C6C6C6',
        cursor: 'pointer',
        display: 'flex',
        height: 150,
        justifyContent: 'center',
        textAlign: 'center',
        width: 150,
        '@media (max-width: 600px)': {
            height: 'auto',
            justifyContent: 'flex-start',
            margin: '10px 0',
            padding: '5px 30px',
            width: '100%'
        },
        "& img": {
            width: 75,
            '@media (max-width: 600px)': {
                width: 40
            }
        },
        "& p": {
            fontSize: 16,
            margin: 0,
            '@media (max-width: 600px)': {
                marginLeft: 10
            }
        },
        "&.selected": {
            border: '2px solid #3cd568',
            color: '#3cd568',
            fontWeight: 'bold'
        }
    },
    paymentMethodContent: {
        '@media (max-width: 600px)': {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row'
        }
    },
    appBar: {
        boxShadow: 'none',
    },
    backButton: {
        marginRight: 50
    }
}));

export default function Step2({ formData, goToNextStep, goToPreviousStep, loading, setFormData, setLoading }) {
    const classes = useStyles();
    const [plan] = usePlan();

    const PaymentMethodOption = ({ label, id }) => (
        <Grid
            item
            onClick={() => setFormData(currentFormData => ({ ...currentFormData, paymentMethod: id }))}
            sm={4}
            xs={12}
        >
            <Box alignItems="center" display="flex" justifyContent="center" >
                <div className={clsx(classes.paymentMethodWrapper, formData.paymentMethod === id && 'selected')}>
                    <div className={classes.paymentMethodContent}>
                        {formData.paymentMethod === id ?
                            <img src={require(`../_assets/img/${id}_selected.png`).default} alt="logo" /> :
                            <img src={require(`../_assets/img/${id}.png`).default} alt="logo" />
                        }
                        <p>{label}</p>
                    </div>
                </div>
            </Box>
        </Grid>
    )

    // We are using a form submit handler to trigger form related events to Google Tag Manager
    const handleSubmit = (e) => {
        e.preventDefault();

        goToNextStep();
    }

    const NavigationButtons = ({ loading, goToPreviousStep, goToNextStep, nextButonId }) => (
        loading ? (
            <Box alignItems="center" display="flex" justifyContent="center" m={8}>
                <CircularProgress color="primary" />
            </Box>
        ) : (
            <Box alignItems="center" display="flex" justifyContent="center" m={8}>
                <Button color="primary" className={classes.backButton} onClick={() => goToPreviousStep()}>
                    Voltar
                </Button>

                <Button id={nextButonId} variant="contained" color="primary" disableElevation type="submit">
                    Próximo
                </Button>
            </Box>
        )
    )

    return (
        <Container maxWidth="sm">
            <Box mb={2}>
                <Typography variant="button" display="block">
                    PASSO <b>2</b> DE <b>2</b>
                </Typography>
                <Typography variant="h5">
                    Detalhes do pagamento
                </Typography>
            </Box>

            <Box mb={6}>
                <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" align="left">
                            Tipo de assinatura
                        </Typography>
                        <Typography variant="subtitle1" align="left">
                            {plan === plans.PLAN_ANNUAL_2023 ? 'Anual' : 'Mensal'}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="subtitle2" align="right">
                            Próxima expiração
                        </Typography>
                        <Typography variant="subtitle1" align="right">
                            {
                                plan === plans.PLAN_ANNUAL_2023 ?
                                    moment().add(12, 'M').format('DD/MM/YYYY') :
                                    moment().add(1, 'M').format('DD/MM/YYYY')
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="subtitle2" align="left">
                            Valor mensal
                        </Typography>
                        <Typography variant="subtitle1" align="left">
                            {plan === plans.PLAN_ANNUAL_2023 ? 'R$ 129,00' : 'R$ 149,00'}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="subtitle2" align="right">
                            Valor a ser pago
                        </Typography>
                        <Typography variant="subtitle1" align="right">
                            <b>{plan === plans.PLAN_ANNUAL_2023 ? 'R$ 1.548,00' : 'R$ 149,00'}</b>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left" style={{fontStyle: 'italic'}}>
                            Comunicações sobre a sua assinatura serão enviadas para o e-mail {formData.email}
                            <a onClick={() => goToPreviousStep()}> (alterar)</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box mb={2}>
                <Typography variant="h5">
                    {plan === plans.PLAN_ANNUAL_2023 ? 'Escolha seu método de pagamento' : 'Finalize a contratação da assinatura'}
                </Typography>
            </Box>

            {plan === plans.PLAN_ANNUAL_2023 &&
                <Grid container direction="row" spacing={2} justify="space-between">
                    <PaymentMethodOption label="Cartão de crédito" id="credit_card" />
                    <PaymentMethodOption label="Boleto" id="bank_slip" />
                    <PaymentMethodOption label="Pix" id="pix" />
                </Grid>
            }

            <Box my={6}>
                {formData.paymentMethod === 'credit_card' &&
                    <CreditCardDataForm
                        formData={formData}
                        disclaimer={<>Ao clicar no botão "Ativar conta", você concorda com os nossos <a href="https://mobilizei.com.br/termos-de-uso" target="_blank" rel="noreferrer">Termos de Uso</a> e a nossa <a href="https://mobilizei.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">Política de Privacidade</a>. Você também garante que os produtos e o conteúdo da sua loja estão de acordo com a <a href="https://support.google.com/googleplay/android-developer/topic/9877466?hl=pt-BR" target="_blank">Política de Conteúdo Restrito da Play Store</a> e seguem as <a target="_blank" href="https://developer.apple.com/app-store/review/guidelines/">Diretrizes da App Store</a>. Sua assinatura irá iniciar imediamente e o valor de {plan === plans.PLAN_MONTHLY_2023 ? 'R$ 149,00 será cobrado mensalmente' : 'R$ 1.548,00 será cobrado anualmente'} no seu cartão de crédito. Você poderá cancelar a sua assinatura a qualquer momento para evitar cobranças futuras.</>}
                        loading={loading}
                        primaryButtonAction={goToNextStep}
                        primaryButtonLabel={'Ativar conta'}
                        primaryButtonId={'hire_credit_card_pay'}
                        secondaryButtonAction={goToPreviousStep}
                        secondaryButtonLabel={'Voltar'}
                        setFormData={setFormData}
                        setLoading={setLoading}
                    />
                }
                {formData.paymentMethod === 'bank_slip' &&
                    <form id="bank_slip_form" onSubmit={handleSubmit}>
                        <Typography variant="body1" align="center">
                            O boleto bancário será enviado por e-mail e você poderá imprimi-lo na página seguinte. O acesso à sua conta será liberado após a compensação do boleto em até 3 dias úteis.
                        </Typography>
                        <NavigationButtons nextButonId={'hire_bank_slip_pay'} loading={loading} goToPreviousStep={goToPreviousStep} />
                    </form>
                }
                {formData.paymentMethod === 'pix' &&
                    <form id="pix_form" onSubmit={handleSubmit}>
                        <Typography variant="body1" align="center">
                            Pagando via Pix o acesso à sua conta será liberado em poucos minutos! <br /><br /> Na próxima página, você deverá escanear o QR Code ou copiar e colar o código do Pix para realizar o pagamento.
                        </Typography>
                        <NavigationButtons nextButonId={'hire_pix_pay'} loading={loading} goToPreviousStep={goToPreviousStep} />
                    </form>
                }
            </Box>
        </Container>
    );
}