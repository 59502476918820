import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import _ from "lodash";
import { mobilizeiSubscriptionsService } from "../_services/mobilizei/subscriptions.service";
import { formatFirestoreTimestamp, getSubscriptionStatusChip } from '../_helpers/mappers';
import { subscriptionStatus } from '../_helpers/constants';
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
    content: {
        color: '#858585'
    }
}))

export default function SubscriptionSection({ subscription }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState({});

    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [openActivateDialog, setOpenActivateDialog] = useState(false);

    const expiresAt = formatFirestoreTimestamp(subscription.expiresAt, 'DD/MM/YYYY');

    const cancelSubscription = () => {
        setLoading(true);

        mobilizeiSubscriptionsService.suspend(subscription.id)
            .then(() => {
                setCurrentStatus(statusesData[subscriptionStatus.SUSPENDED])
                toast.success('Assinatura cancelada com sucesso!');
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível cancelar a assinatura. Por favor, tente novamente mais tarde. (#${errorCode})`);
            })
            .finally(() => {
                setOpenCancelDialog(false);
                setLoading(false);
            })
    }

    const activateSubscription = () => {
        setLoading(true);

        mobilizeiSubscriptionsService.activate(subscription.id)
            .then(() => {
                setCurrentStatus(statusesData[subscriptionStatus.ACTIVE])
                toast.success('Assinatura reativada com sucesso!');
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível reativar a assinatura. Por favor, tente novamente mais tarde. (#${errorCode})`);
            })
            .finally(() => {
                setOpenActivateDialog(false);
                setLoading(false);
            })
    }

    const statusesData = {
        [subscriptionStatus.PENDING]: {
            chip: getSubscriptionStatusChip(subscriptionStatus.PENDING), 
            message: 'Efetue o pagamento da fatura em aberto para ativar a sua assinatura.'
        },
        [subscriptionStatus.ACTIVE]: {
            chip: getSubscriptionStatusChip(subscriptionStatus.ACTIVE), 
            message: `A próxima data de cobrança é ${expiresAt}.`,
            hasAction: true,
            actionLabel: 'Cancelar assinatura',
            actionMethod: () => setOpenCancelDialog(true)
        },
        [subscriptionStatus.PENDING_SUSPENSION]: {
            chip: getSubscriptionStatusChip(subscriptionStatus.PENDING_SUSPENSION), 
            message: `Sua assinatura foi cancelada e permanecerá ativa até o dia ${expiresAt}. Não será gerado nenhuma nova fatura ou cobrança.`,
            hasAction: true,
            actionLabel: 'Reativar assinatura',
            actionMethod: () => setOpenActivateDialog(true)
        },
        [subscriptionStatus.EXPIRED]: {
            chip: getSubscriptionStatusChip(subscriptionStatus.EXPIRED), 
            message: 'Efetue o pagamento da fatura em aberto para evitar o cancelamento da sua assinatura.',
            hasAction: true,
            actionLabel: 'Cancelar assinatura',
            actionMethod: () => setOpenCancelDialog(true)
        },
        [subscriptionStatus.SUSPENDED]: {
            chip: getSubscriptionStatusChip(subscriptionStatus.SUSPENDED), 
            message: 'Não será gerado nenhuma nova fatura ou cobrança'
        }
    }

    useEffect(() => {
        setCurrentStatus(statusesData[subscription.status])
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <hr />
                </Grid>

                <Grid item xs={12} md={5}>
                    <Typography variant="h5">
                        Assinatura
                    </Typography>
                </Grid>

                <Grid item xs={12} md={7} className={classes.content}>
                    <Typography variant="body1">
                        O status da sua assinatura é {currentStatus.chip}
                    </Typography>

                    <Box my={2}>
                        <Typography variant="body1">
                            {currentStatus.message}
                        </Typography>
                    </Box>
                </Grid>

                {currentStatus.hasAction &&
                    <Grid item xs={12}>
                        <Box alignItems="center" display="flex" justifyContent="flex-end">
                            <Button color="primary" onClick={currentStatus.actionMethod}>
                                {currentStatus.actionLabel}
                            </Button>
                        </Box>
                    </Grid>
                }
            </Grid>

            {/* Cancel subscription dialog START */}
            <Dialog
                open={openCancelDialog}
                onClose={() => setOpenCancelDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading ? (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Cancelando sua assinatura...
                        </DialogContentText>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={4}>
                                <CircularProgress color="primary" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                ) : (
                    <>
                        <DialogTitle id="alert-dialog-title">Cancelar sua assinatura?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Ao cancelar sua assinatura, ela permanecerá ativa até o dia {expiresAt} e nenhuma nova cobrança ou fatura será gerada.
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description">
                                Após essa data, seus aplicativos serão desativados e você não poderá mais acessar o seu painel de controle.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => cancelSubscription()} color="secondary" autoFocus>
                                Sim, desejo cancelar a minha assinatura
                            </Button>

                            <Button onClick={() => setOpenCancelDialog(false)} color="primary">
                                Não, desejo continuar com a minha assinatura
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {/* Cancel subscription dialog END */}

            {/* Reactivate subscription dialog START */}
            <Dialog
                open={openActivateDialog}
                onClose={() => setOpenCancelDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading ? (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Reativando sua assinatura...
                        </DialogContentText>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={4}>
                                <CircularProgress color="primary" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                ) : (
                    <>
                        <DialogTitle id="alert-dialog-title">Reativar sua assinatura?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Ao reativar sua assinatura, uma nova fatura será gerada próximo da data de vencimento da sua mensalidade e deverá ser paga para manter a sua mensalidade ativa.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenActivateDialog(false)} color="secondary" autoFocus>
                                Não, desejo manter a minha assinatura cancelada
                            </Button>

                            <Button onClick={() => activateSubscription()} color="primary">
                                Sim, desejo reativar a minha assinatura
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {/* Reactivate subscription dialog END */}
        </>
    );
}