import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import UserDataForm from '../_components/UserDataForm';
import { useHistory } from 'react-router';

export default function Step1({ formData, goToStep2, setFormData }) {
    const history = useHistory();

    return (
        <Container maxWidth="sm">
            <Box mb={6}>
                <Typography variant="button" display="block">
                    PASSO <b>1</b> DE <b>2</b>
                </Typography>
                <Typography variant="h4">
                    Insira seus dados pessoais
                </Typography>
            </Box>

            <UserDataForm 
                formData={formData} 
                primaryButtonAction={goToStep2} 
                primaryButtonLabel={'Próximo'} 
                secondaryButtonAction={() => history.push('/pricing')} 
                secondaryButtonLabel={'Voltar'} 
                primaryButtonId={'hire_select_payment_method'}
                setFormData={setFormData} 
            />
        </Container>
    );
}