import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from "../_context/store.context";
import { Box, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import MainLayout from '../_layouts/main';
import { mobilizeiSubscriptionsService } from "../_services/mobilizei/subscriptions.service";
import UserDataForm from '../_components/UserDataForm';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
    title: {
        "& span": {
            position: 'relative',
            "&::after": {
                content: '""',
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "100%",
                height: ".26em",
                borderRadius: "20px",
                backgroundColor: "#3cd568",
                zIndex: -1,
                WebkitTransform: "translateY(-50%)",
                msTransform: "translateY(-50%)",
                transform: "translateY(-50%)"
            }
        }

    }
}))

export default function UserDataPage() {
    const classes = useStyles();
    const history = useHistory();
    const [store] = useStore();

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [formData, setFormData] = useState({})

    useEffect(() => {
        mobilizeiSubscriptionsService.getById(store.subscriptionId)
            .then(subscriptionData => {
                const billingInformation = _.get(subscriptionData, 'billingInformation', {});
                const { city, complement, cpfCnpj, district, email, name, phone, number, state, street, zipCode } = billingInformation;

                setFormData({ city, complement, cpfCnpj, district, email, name, phone, number, state, street, zipCode })
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
            })
    }, []);

    const submit = () => {
        setSaving(true);

        mobilizeiSubscriptionsService.updateBillingInformation(store.subscriptionId, formData)
            .then(() => {
                toast.success('Alterações salvas com sucesso!');
                history.goBack();
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível atualizar seus dados. Por favor, tente novamente mais tarde. (#${errorCode})`);
            })
            .finally(() => {
                setSaving(false);
            })
    }

    return (
        <MainLayout>
            <Container maxWidth="sm">
                <Box mb={4}>
                    <Typography align="center" variant="h3" className={classes.title}>
                        Dados de Fatur<span>amento</span>
                    </Typography>
                </Box>

                {loading ? (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '50vh' }}
                    >
                        <Grid item xs={4}>
                            <CircularProgress color="primary" />
                        </Grid>
                    </Grid>
                ) : (
                    <UserDataForm
                        formData={formData}
                        loading={saving}
                        primaryButtonAction={() => submit()}
                        primaryButtonLabel={'Salvar'}
                        secondaryButtonAction={() => history.goBack()}
                        secondaryButtonLabel={'Voltar'}
                        setFormData={setFormData}
                    />
                )}
            </Container>
        </MainLayout>
    );
}