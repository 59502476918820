import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import _ from "lodash";
import { mobilizeiSubscriptionsService } from "../_services/mobilizei/subscriptions.service";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
    content: {
        color: '#858585'
    }
}))

export default function PaymentMethodSection({ subscription }) {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const [creditCardData, setCreditCardData] = useState(_.get(subscription, 'creditCardData', {}));
    const { brand, displayNumber, holderName, month, year } = creditCardData;

    const submit = () => {
        setLoading(true);

        mobilizeiSubscriptionsService.removePaymentMethod(subscription.id)
            .then(() => {
                setCreditCardData({})
                toast.success('Cartão removido com sucesso!');
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível remover o cartão. Por favor, tente novamente mais tarde. (#${errorCode})`);
            })
            .finally(() => {
                setOpenDialog(false);
                setLoading(false);
            })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <hr />
                </Grid>

                <Grid item xs={12} md={5}>
                    <Typography variant="h5">
                        Método de Pagamento
                    </Typography>
                </Grid>

                {_.isEmpty(creditCardData) ? (
                    <Grid item xs={12} md={7} className={classes.content}>
                        <Box my={4}>
                            <Typography variant="body1" align="center">
                                Você não possui um método de pagamento cadastrado.
                            </Typography>

                            <Box alignItems="center" display="flex" justifyContent="center" mt={4}>
                                <Button color="primary" onClick={() => history.push("/payment_method")}>
                                    Cadastrar cartão de crédito
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} md={7} className={classes.content}>
                            <Box mb={2}>
                                <Typography variant="body1">
                                    {displayNumber} <br />
                                    {holderName} <br />
                                    {month}/{year}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box alignItems="center" display="flex" justifyContent="flex-end">
                                <Button color="primary" onClick={() => setOpenDialog(true)}>
                                    Remover cartão
                                </Button>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading ? (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Removendo o cartão de crédito da sua conta...
                        </DialogContentText>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={4}>
                                <CircularProgress color="primary" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                ) : (
                    <>
                        <DialogTitle id="alert-dialog-title">Remover cartão de crédito?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Ao remover o seu cartão de crédito a assinatura da Mobilizei deixará de ser cobrada automaticamente.
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description">
                                Todo o mês você receberá uma fatura no seu e-mail e poderá pagá-la via cartão de crédito, boleto ou Pix para continuar utilizando o serviço.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)} color="secondary">
                                Cancelar
                            </Button>
                            <Button onClick={() => submit()} color="primary" autoFocus>
                                Remover cartão
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
}