import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import withAuthorization from '../_auth/withAuthorization';
import MyAccountPage from '../MyAccountPage/MyAccountPage';
import SubscriptionPage from '../SubscriptionPage/SubscriptionPage';
import PricingPage from '../PricingPage/PricingPage';
import UserDataPage from '../UserDataPage/UserDataPage';
import PaymentMethodPage from '../PaymentMethodPage/PaymentMethodPage';
import { storeStatus } from '../_helpers/constants';
import InvoicePage from '../InvoicePage/InvoicePage';
import ErrorPage from '../ErrorPage/ErrorPage';

const AppRouter = () => {
    // The store has to be on the ACTIVE status AND have a subscriptionId
    const activeOnly = authUser => authUser.status === storeStatus.ACTIVE && !!authUser.subscriptionId;

    // The store must not be on the ACTIVE status OR does not have a subscriptionId
    const notActiveOnly = authUser => authUser.status !== storeStatus.ACTIVE || !authUser.subscriptionId; // ON_TRIAL, TRIAL_ENDED, SUSPENDED

    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/" exact component={withAuthorization(activeOnly)(MyAccountPage)} />
                    <Route path="/billing_data" component={withAuthorization(activeOnly)(UserDataPage)} />
                    <Route path="/payment_method" component={withAuthorization(activeOnly)(PaymentMethodPage)} />
                    <Route path="/hire" component={withAuthorization(notActiveOnly)(SubscriptionPage)} />
                    <Route path="/pricing" component={withAuthorization(notActiveOnly)(PricingPage)} />
                    <Route path="/faturas/:invoiceId?" component={InvoicePage} />
                    <Route path="/error" component={ErrorPage} />
                    <Redirect to="/" />
                </Switch>
            </div>
        </Router>
    )
}

export default AppRouter;