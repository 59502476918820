const paymentMethods = {
    BANK_SLIP: "bank_slip",
    CREDIT_CARD: "credit_card",
    PIX: "pix"
}

const plans = {
    PLAN_ANNUAL_2023: 'plan_annual_2023',
    PLAN_MONTHLY_2023: 'plan_monthly_2023'
}

const platforms = {
    NUVEMSHOP: 'nuvemshop',
    TRAY: 'tray'
}

const storeStatus = {
    ON_TRIAL: "on_trial",
    TRIAL_ENDED: "trial_ended",
    ACTIVE: "active",
    SUSPENDED: "suspended",
    ARCHIVED: "archived"
}

const subscriptionStatus = {
    UNDEFINED: "undefined",
    PENDING: "pending",
    ACTIVE: "active",
    EXPIRED: "expired",
    PENDING_SUSPENSION: "pending_suspension",
    SUSPENDED: "suspended"
}

module.exports = {
    paymentMethods,
    plans,
    platforms,
    storeStatus,
    subscriptionStatus
}