import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export default function ErrorPage() {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={4}>
                <Typography variant="body1" align="center">
                    Não foi possível carregar esta página. Por favor, volte à página anterior e tente novamente.
                </Typography>
            </Grid>
        </Grid>
    )
}